import { GetEndpoint } from '../../src/library/ajax/getEndpoint';
import { PostEndpoint } from '../../src/library/ajax/postEndpoint';
import { DeleteEndpoint } from '../../src/library/ajax/deleteEndpoint';
import { CopyConfigType, CpqBackendExchangeFormatType } from '../types/ekitCpqController/';
import { PutEndpoint } from '../../src/library/ajax/putEndpoint';

// https://wiki.namics.com/pages/viewpage.action?title=01+-+Storefront+CPQ&spaceKey=DOKA

export const releaseConfiguration = DeleteEndpoint.create<
	{
		token: string;
		cartCode: string;
		language: string;
		configId: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	CpqBackendExchangeFormatType
>({
	url: ({ cartCode, language, rootB2BUnit, configId, hybrisStore, user }) => {
		return (
			`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/` +
			`configuration/${configId}/release?lang=${language}&rootB2BUnit=${rootB2BUnit}`
		);
	},
	options: ({ token }) => ({
		headerOptions: {
			authToken: token,
		},
		afterSuccess: () => {
			getConfigurationById.clearCache();
			getConfigurationByIdWithHeadPosition.clearCache();
		},
	}),
});

/**
 * Returns a default product config (i.e. for creating a new configuration)
 */
export const getDefaultConfigurationForProduct = GetEndpoint.create<
	{
		token: string;
		cartCode: string;
		language: string;
		productCode: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		customCstics?: string;
	},
	CpqBackendExchangeFormatType
>({
	url: ({ cartCode, language, rootB2BUnit, productCode, hybrisStore, user, customCstics }) => {
		return (
			`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/carts/${cartCode}/` +
			`configuration/product/${productCode}?lang=${language}&rootB2BUnit=${rootB2BUnit}${
				customCstics ? '&customCstics=' + customCstics : ''
			}`
		);
	},
	cacheEnabled: false,
	options: ({ token }) => ({
		headerOptions: {
			authToken: token,
		},
	}),
});

export const getEmksDefaultConfigurationForProduct = GetEndpoint.create<
	{
		token: string;
		cartCode: string;
		language: string;
		productCode: string;
		hybrisStore: string;
		user: string;
		headPosition: string;
		rootB2BUnit: string;
	},
	CpqBackendExchangeFormatType
>({
	url: ({ cartCode, language, rootB2BUnit, productCode, hybrisStore, user, headPosition }) => {
		return (
			`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/` +
			`configuration/product/${productCode}/headposition/${headPosition}?lang=${language}&rootB2BUnit=${rootB2BUnit}`
		);
	},
	cacheEnabled: false,
	options: ({ token }) => ({
		headerOptions: {
			authToken: token,
		},
	}),
});

/**
 * Returns the list representation of the default product config inside a head position context.
 * This does not generate a configId itself and only has the display string and valid flag.
 */
export const getListConfigurationDataForHeadPosition = GetEndpoint.create<
	{
		token: string;
		cartCode: string;
		language: string;
		productCode: string;
		headPosition: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		technicalSystemCode?: string;
	},
	CpqBackendExchangeFormatType
>({
	url: ({ cartCode, language, rootB2BUnit, productCode, hybrisStore, user, headPosition, technicalSystemCode }) => {
		return (
			`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/` +
			`configuration/product/listitem/${productCode}/headposition/${headPosition}?lang=${language}&rootB2BUnit=${rootB2BUnit}`
			+ (technicalSystemCode ? `&technicalSystemCode=${technicalSystemCode}` : ``)
		);
	},
	cacheEnabled: false,
	options: ({ token }) => ({
		headerOptions: {
			authToken: token,
		},
	}),
});

/**
 * Returns a default product config (i.e. for creating a new configuration)
 */
export const getDefaultConfigurationForItemInfo = GetEndpoint.create<
	{
		token: string;
		cartCode: string;
		language: string;
		hybrisStore: string;
		user: string;
		itemInfo: string;
		headPosition: string;
		rootB2BUnit: string;
		productCode?: string;
	},
	CpqBackendExchangeFormatType
>({
	url: ({ cartCode, language, rootB2BUnit, hybrisStore, user, itemInfo, headPosition, productCode }) => {
		return (
			`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/` +
			`configuration/headposition/${headPosition}/itemInfo/${itemInfo}?lang=${language}&rootB2BUnit=${rootB2BUnit}&productCode=${productCode}`
		);
	},
	cacheEnabled: false,
	options: ({ token }) => ({
		headerOptions: {
			authToken: token,
		},
	}),
});

/**
 * Returns a default product config (i.e. for creating a new configuration)
 */
export const getConfigurationAdditionalInfo = GetEndpoint.create<
	{
		token: string;
		cartCode: string;
		language: string;
		hybrisStore: string;
		user: string;
		itemInfo: string;
		headPosition: string;
		rootB2BUnit: string;
		productCode?: string;
	},
	CpqBackendExchangeFormatType
>({
	url: ({ cartCode, language, rootB2BUnit, hybrisStore, user, itemInfo, headPosition, productCode }) => {
		return (
			`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/` +
			`configuration/headposition/${headPosition}/itemInfo/${itemInfo}/additional?lang=${language}&rootB2BUnit=${rootB2BUnit}&productCode=${productCode}`
		);
	},
	cacheEnabled: true,
	options: ({ token }) => ({
		headerOptions: {
			authToken: token,
		},
	}),
});

/**
 * Returns a copy of an existing product configuration (i.e. for copy functionality in reorder)
 * OCC5: https://wiki.namics.com/pages/viewpage.action?pageId=14750244
 */
export const copyExistingProductConfiguration = PostEndpoint.create<
	{
		token: string;
		cartCode: string;
		language: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	Array<CopyConfigType>,
	{ configurations: Array<{ key: string; value: CpqBackendExchangeFormatType }> }
>({
	url: ({ cartCode, language, rootB2BUnit, hybrisStore, user }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/carts/${cartCode}/configuration/copy/configurations?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({
		headerOptions: {
			authToken: token,
		},
	}),
});

/**
 * Returns the product config which belongs to the configId
 */
export const getConfigurationById = GetEndpoint.create<
	{
		token: string;
		cartCode: string;
		language: string;
		productCode: string;
		configId: string;
		hybrisStore: string;
		user: string;
		createDraft: boolean;
		rootB2BUnit: string;
	},
	CpqBackendExchangeFormatType
>({
	url: ({ configId, language, rootB2BUnit, productCode, cartCode, hybrisStore, user, createDraft }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/carts/${cartCode}/configuration/product/${productCode}/${configId}
		?lang=${language}&createDraft=${createDraft}&rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Returns the product config which belongs to the configId
 */
export const getConfigurationByIdWithHeadPosition = GetEndpoint.create<
	{
		token: string;
		cartCode: string;
		language: string;
		productCode: string;
		configId: string;
		hybrisStore: string;
		user: string;
		headPosition: string;
		createDraft: boolean;
		rootB2BUnit: string;
	},
	CpqBackendExchangeFormatType
>({
	url: ({ configId, language, rootB2BUnit, productCode, cartCode, hybrisStore, user, createDraft, headPosition }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/carts/${cartCode}/configuration/product/${productCode}/${configId}
		?lang=${language}&createDraft=${createDraft}&rootB2BUnit=${rootB2BUnit}
		${headPosition ? `&headposition=${headPosition}` : ''}`,
	cacheEnabled: true,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Returns the product config Id dependent on the cart entry Pk
 */
export const getConfigurationIdByCartEntryPk = GetEndpoint.create<
	{
		token: string;
		cartCode: string;
		language: string;
		cartEntryPk: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	string
>({
	url: ({ cartEntryPk, cartCode, language, rootB2BUnit, hybrisStore, user }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/carts/${cartCode}/configuration/cart/${cartEntryPk}?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Returns the product config, which belongs to the external config id
 */
export const loadExternalConfigurationById = GetEndpoint.create<
	{
		token: string;
		cartCode: string;
		language: string;
		externalConfigId: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		customCstics?: string;
	},
	string
>({
	url: ({ externalConfigId, cartCode, language, rootB2BUnit, hybrisStore, user, customCstics }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/carts/${cartCode}/configuration/config/${externalConfigId}?lang=${language}&rootB2BUnit=${rootB2BUnit}${
			customCstics ? '&customCstics=' + customCstics : ''
		}`,
	cacheEnabled: false,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Posts the user's product config. Returns the updated product config.
 */
export const updateConfigurationData = PutEndpoint.create<
	{
		token: string;
		cartCode: string;
		language: string;
		productCode: string;
		configId: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		includeShortText: boolean;
	},
	CpqBackendExchangeFormatType,
	// Posting an update will return the validated and verified form
	// which might include form errors or even new form fields
	CpqBackendExchangeFormatType
>({
	url: ({ configId, productCode, cartCode, language, rootB2BUnit, hybrisStore, user, includeShortText }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/carts/${cartCode}/configuration/product/${productCode}/${configId}?lang=${language}&rootB2BUnit=${rootB2BUnit}&includeShortText=${includeShortText}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getConfigurationById.clearCache();
			getConfigurationByIdWithHeadPosition.clearCache();
		},
	}),
});

/**
 * Posts the user's product config and respects rule set derived from IAS. Returns the updated product config.
 */
export const emksUpdateConfigurationData = PutEndpoint.create<
	{
		token: string;
		cartCode: string;
		language: string;
		productCode: string;
		configId: string;
		hybrisStore: string;
		user: string;
		headPosition: string;
		rootB2BUnit: string;
		includeShortText: boolean;
	},
	CpqBackendExchangeFormatType,
	// Posting an update will return the validated and verified form
	// which might include form errors or even new form fields
	CpqBackendExchangeFormatType
>({
	url: ({ configId, productCode, cartCode, language, rootB2BUnit, hybrisStore, user, headPosition, includeShortText }) => {
		return `/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/configuration/product/${productCode}/${configId}/headposition/${headPosition}?lang=${language}&rootB2BUnit=${rootB2BUnit}&includeShortText=${includeShortText}`;
	},

	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getConfigurationById.clearCache();
			getConfigurationByIdWithHeadPosition.clearCache();
		},
	}),
});

export const addToCart = PostEndpoint.create<
	{
		token: string;
		cartCode: string;
		language: string;
		productCode: string;
		configId: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		qty :number;
	},
	CpqBackendExchangeFormatType,
	null
	>({
	url: ({ cartCode, language, rootB2BUnit, productCode, configId, hybrisStore, user,qty }) => {

		return	`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/carts/${cartCode}/configuration/product/${productCode}/${configId}/addToCart?qty=${qty}&lang=${language}&rootB2BUnit=${rootB2BUnit}`;
	},
	options: ({ token }) => ({ headerOptions: { authToken: token } }),

});

export const updateCartEntry = PostEndpoint.create<
	{
		token: string;
		cartCode: string;
		language: string;
		productCode: string;
		configId: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
		cartEntryPk: string;
	},
	CpqBackendExchangeFormatType,
	null
>({
	url: ({ cartCode, language, rootB2BUnit, productCode, configId, hybrisStore, user, cartEntryPk }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/carts/${cartCode}/configuration/cartentry/${cartEntryPk}/${configId}?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Posts the user's product config. Returns the updated product config.
 */
export const saveExternalConfiguration = PostEndpoint.create<
	{
		token: string;
		cartCode: string;
		language: string;
		productCode: string;
		configId: string;
		hybrisStore: string;
		user: string;
		partnerId: string;
		rootB2BUnit: string;
	},
	CpqBackendExchangeFormatType,
	// Posting a save will return the external config idas string, with which you can
	// edit at a later point.
	string
>({
	url: ({ cartCode, language, rootB2BUnit, productCode, configId, hybrisStore, user, partnerId }) => {
		const partnerParam = partnerId ? `&partnerId=${partnerId}` : '';
		return `/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/carts/${cartCode}/configuration/product/${productCode}/${configId}/info?lang=${language}&rootB2BUnit=${rootB2BUnit}${partnerParam}`;
	},
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

/**
 * Updates the user's existing product config.
 */
export const updateExternalConfiguration = PutEndpoint.create<
	{
		token: string;
		cartCode: string;
		language: string;
		productCode: string;
		configId: string;
		externalConfigId: string;
		hybrisStore: string;
		user: string;
		partnerId: string;
		rootB2BUnit: string;
	},
	CpqBackendExchangeFormatType,
	null
>({
	url: ({
		cartCode,
		language,
		rootB2BUnit,
		productCode,
		configId,
		externalConfigId,
		hybrisStore,
		user,
		partnerId,
	}) => {
		const partnerParam = partnerId ? `&partnerId=${partnerId}` : '';
		return `/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/carts/${cartCode}/configuration/product/${productCode}/${configId}/info/${externalConfigId}?lang=${language}&rootB2BUnit=${rootB2BUnit}${partnerParam}`;
	},
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});
