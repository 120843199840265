import {AbstractEndpoint, EndpointOptions} from './common';
import {parseResponse} from './responseParser';

export class GetEndpoint<TKeys extends { signal?: AbortSignal }, TResult> extends AbstractEndpoint<
	TKeys,
	void,
	TResult
> {
	/**
	 * Perform GET request
	 */
	protected request = (keys: TKeys): Promise<TResult> => {
		const requestPromise: Promise<TResult> = new Promise((resolve, reject) => {
			const request = new XMLHttpRequest();
			request.open('GET', this.getUrl(keys), true);
			request.timeout = this.getOptions(keys).timeout || 180000;
			// Set request headers from the given header json
			// Parse header e.g. { "Authorization": "Bearer 2302601b87dfd19035d9f89ad79a3682" }
			const headerObject = JSON.parse(this.getHeader(keys)) as { [key: string]: string };
			const headerOptions = Object.keys(headerObject);
			headerOptions.forEach(headerOption => {
				// headerOption: "Authorization"
				// headerObject[headerOption]: "Bearer 2302601b87dfd19035d9f89ad79a3682"
				request.setRequestHeader(headerOption, headerObject[headerOption]);
			});
			request.onload = () => {
				const contentType = request.getResponseHeader('Content-Type') || '';
				const containsHtml = contentType.indexOf('html') !== -1;

				if (request.status < 200 || request.status >= 400) {
					return reject(request);
				}

				if (containsHtml) {
					return resolve(request.responseText as any);
				}

				let parsed: TResult;
				try {
					parsed = request.responseText ? parseResponse<TResult>(request.responseText) : undefined;
				} catch (e) {
					console.error(e);
					return reject(request);
				}
				resolve(parsed);
			};
			request.onerror = () => {
				reject(request);
			};
			request.send();

			const ajaxSignal = this.getAjaxSignal(keys);
			if (ajaxSignal) {
				ajaxSignal.onabort = () => {
					console.log(`GetEndpoint: request aborted by user ${this.getUrl(keys)}`);
					request.abort();
					resolve(undefined);
				};
			}
		});
		return requestPromise;
	};

	private getAjaxSignal = (keys: TKeys): AbortSignal | undefined => keys.signal;

	/**
	 * Factory method to create GET endpoint
	 */
	public static create = <TKeys, TResult>({
		url,
		cacheEnabled,
		options,
	}: {
		url: (keys: TKeys) => string;
		cacheEnabled: boolean;
		options?: (keys: TKeys) => EndpointOptions<TKeys, void, TResult>;
	}): GetEndpoint<TKeys, TResult> =>
		new GetEndpoint<TKeys, TResult>({
			url,
			cacheEnabled,
			options,
		});
}
