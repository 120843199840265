import { GetEndpoint } from '../../src/library/ajax/getEndpoint';

// Returns a random count.
export const getExampleCount = GetEndpoint.create<
	{
		initialCount: number;
	},
	{
		count: number;
	}
>({
	url: ({}) => `/dormakabacommercewebservices/v2/example-endpoint`,
	cacheEnabled: false,
});
