import { DeleteEndpoint } from '../../src/library/ajax/deleteEndpoint';
import { GetEndpoint } from '../../src/library/ajax/getEndpoint';
import { PostEndpoint } from '../../src/library/ajax/postEndpoint';
import { EPOS, MediaType } from '../types/emksEPOSController';
import { ElectronicalKeySystem } from '../types/emksKeySystemController';

// DOCS: https://wiki.namics.com/pages/viewpage.action?spaceKey=DOKA&title=16+-+OCC+Electronic+Positions

export type KeySystem_EPOS = Pick<ElectronicalKeySystem, 'electronicPositions'>;

// Returns a list of Electronic Positions.
export const getEposByHeadPosition = GetEndpoint.create<
	{
		headPosition: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	KeySystem_EPOS
>({
	url: ({ headPosition, hybrisStore, user, rootB2BUnit }) => {
		return `/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/epos/headposition/${headPosition}?rootB2BUnit=${rootB2BUnit}`;
	},
	cacheEnabled: true,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

// Returns a list of available media types.
export const getMediaTypes = GetEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	{ mediaTypes: Array<MediaType> }
>({
	url: ({ hybrisStore, user, rootB2BUnit }) => {
		return `/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/epos/mediatypes?rootB2BUnit=${rootB2BUnit}`;
	},
	cacheEnabled: true,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

// Updates a certain Electronic Position.
export const updateEpos = PostEndpoint.create<
	{
		headPosition: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	EPOS,
	void
>({
	url: ({ headPosition, hybrisStore, user, rootB2BUnit }) => {
		return `/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/epos/headposition/${headPosition}?rootB2BUnit=${rootB2BUnit}`;
	},
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getEposByHeadPosition.clearCache();
			getMediaTypes.clearCache();
		},
	}),
});

// Deletes an Electronic Position.
export const deleteEpos = DeleteEndpoint.create<
	{
		headPosition: string;
		electronicPositionId: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	void
>({
	url: ({ headPosition, hybrisStore, user, rootB2BUnit, electronicPositionId }) => {
		return `/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/epos/headposition/${headPosition}/id/${electronicPositionId}?rootB2BUnit=${rootB2BUnit}`;
	},
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getEposByHeadPosition.clearCache();
			getMediaTypes.clearCache();
		},
	}),
});

// Delete Uploaded Files.
export const deleteUploadedFile = DeleteEndpoint.create<
	{
		fileReference: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	void
>({
	url: ({ fileReference, hybrisStore, user, rootB2BUnit }) => {
		return `/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/fileupload/delete?fileReference=${fileReference}&rootB2BUnit=${rootB2BUnit}`;
	},
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

export const getCodingTypes = GetEndpoint.create<
	{
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	{ electronicPositionCodingTypes: Array<string> }
>({
	url: ({ hybrisStore, user, rootB2BUnit }) => {
		return `/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/epos/codingtypes?rootB2BUnit=${rootB2BUnit}`;
	},
	cacheEnabled: true,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});
