import downloadjs from 'downloadjs';
import { AbstractEndpoint, EndpointOptions } from './common';

export type TDownloadKeys = {
	fileName: string;
	mimeType?: string;
	signal?: AbortSignal;
};

export class DownloadEndpoint<TKeys extends TDownloadKeys, TResult> extends AbstractEndpoint<TKeys, void, TResult> {
	private readonly useFileNameFromResponse: boolean;

	constructor({
		url,
		useFileNameFromResponse,
		options,
	}: {
		url: (keys: TKeys) => string;
		useFileNameFromResponse: boolean;
		options?: (keys: TKeys) => EndpointOptions<TKeys, void, TResult>;
	}) {
		super({ url, options });
		this.useFileNameFromResponse = useFileNameFromResponse;
	}

	/**
	 * Perform DOWNLOAD request
	 */
	protected request = async (keys: TKeys): Promise<TResult> => {
		// Polyfill fetch for IE11 if neccessary
		const fetch = window.fetch || (await import('fetch-ponyfill')).default().fetch;
		// Start download
		try {
			const response = await fetch(this.getUrl(keys), {
				method: 'GET',
				headers: JSON.parse(this.getHeader(keys)),
				signal: this.getAjaxSignal(keys),
			});
			// Handle Errors
			if (response.status >= 400) {
				return Promise.reject({
					status: response.status,
					responseText: await response.text(),
				});
			}

			const blob = await response.blob();
			let fileName = this.getFileName(keys);
			if (this.useFileNameFromResponse && response.headers.get('FileName') != null) {
				fileName = response.headers.get('FileName');
			}
			// Pipe server request to the browser download
			keys.mimeType ? downloadjs(blob, fileName, this.getMimeType(keys)) : downloadjs(blob, fileName);
		} catch (error) {
			if (error.name === 'AbortError') {
				console.log(`DownloadEndpoint: request aborted by user ${this.getUrl(keys)}`);
			} else {
				console.error(JSON.stringify(error));
			}
		}
	};

	private getFileName = (keys: TKeys): string => {
		const filenameParts = keys.fileName.split(/\{([^\}]+)\}/);
		return filenameParts.join('');
	};

	private getMimeType = (keys: TKeys): string => {
		const mimeTypeParts = keys.mimeType.split(/\{([^\}]+)\}/);
		return mimeTypeParts.join('');
	};

	private getAjaxSignal = (keys: TKeys): AbortSignal | undefined => keys.signal;

	/**
	 * Factory method to create delete endpoint
	 */
	public static create = <TKeys extends TDownloadKeys, TResult>({
		url,
		useFileNameFromResponse,
		options,
	}: {
		url: (keys: TKeys) => string;
		useFileNameFromResponse: boolean;
		options?: (keys: TKeys) => EndpointOptions<TKeys, void, TResult>;
	}): DownloadEndpoint<TKeys, TResult> =>
		new DownloadEndpoint<TKeys, TResult>({
			url,
			useFileNameFromResponse,
			options,
		});
}
