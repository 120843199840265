/* eslint-disable default-case */
/* eslint-disable complexity */
/* eslint-disable require-jsdoc */
export function trimSpecialChars(str: string): string {
	return str.replace(/[^a-zA-Z0-9]/g, '');
}

// This will allow German characters
export function trimSpecialCharsOnly(str: string): string {
	str = str.replace(/[^a-zA-Z0-9\-./]/g, '');
	while (str.slice(-1) === '.') {
		str = str.slice(0, -1);
	}
	return str;
}

export function matchesNumberFormat(str: string): boolean {
	const regexNumber = /^[+-]?\d+(\.\d+)?$/;
	return regexNumber.test(str);
}

export function matchesDateIsoFormat(str: string): boolean {
	const regexDateISO = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d{1,3})?([+-][0-2]\d:[0-5]\d|Z)$/;
	return regexDateISO.test(str);
}

