import {AbstractEndpoint, EndpointOptions} from './common';
import {parseResponse} from './responseParser';

export class PostEndpoint<TKeys, TBody, TResult> extends AbstractEndpoint<TKeys, TBody, TResult> {
	/**
	 * Perform POST request
	 */
	protected request = (keys: TKeys, body: TBody): Promise<TResult> => {
		const requestPromise: Promise<TResult> = new Promise((resolve, reject) => {
			const request = new XMLHttpRequest();
			request.open('POST', this.getUrl(keys), true);
			request.timeout = this.getOptions(keys).timeout || 180000;
			const headerObject = JSON.parse(this.getHeader(keys)) as { [key: string]: string };
			const headerOptions = Object.keys(headerObject);
			headerOptions.forEach(headerOption => {
				// headerOption: "Authorization"
				// headerObject[headerOption]: "Bearer 2302601b87dfd19035d9f89ad79a3682"
				request.setRequestHeader(headerOption, headerObject[headerOption]);
			});
			request.onload = () => {
				if (request.status >= 200 && request.status < 400) {
					let parsed;
					try {
						parsed = request.responseText ? parseResponse<TResult>(request.responseText) : undefined;
					} catch (e) {
						console.error(e);
						return reject(request);
					}
					resolve(parsed);
				} else {
					reject(request);
				}
			};
			request.onerror = () => {
				reject(request);
			};
			if (body instanceof FormData) {
				request.send(body);
			} else {
				request.send(JSON.stringify(body));
			}
		});
		return requestPromise;
	};

	/**
	 * Factory method to create POST endpoint
	 */
	public static create = <TKeys, TBody, TResult>({
		url,
		options,
	}: {
		url: (keys: TKeys) => string;
		options?: (keys: TKeys) => EndpointOptions<TKeys, TBody, TResult>;
	}): PostEndpoint<TKeys, TBody, TResult> => new PostEndpoint<TKeys, TBody, TResult>({ url, options });
}
