// Fancybox has no export and uses jQuery directly from the window
(window as any).jQuery = require('jquery');
(window as any).$ = require('jquery');
require('@fancyapps/fancybox');

// Allow global access (using webpack externals)
// see vendors.json for details
(window as any).dormakaba.vendorTerrific = (window as any).dormakaba.vendorTerrific || {};

if (!(window as any).dormakaba.vendorTerrific.hasOwnProperty('vendor/jquery')) {
	Object.defineProperty((window as any).dormakaba.vendorTerrific, 'vendor/jquery', {
		value: require('jquery'),
		writable: false,
		enumerable: false,
		configurable: false,
	});
}

if (!(window as any).dormakaba.vendorTerrific.hasOwnProperty('vendor/terrific')) {
	Object.defineProperty((window as any).dormakaba.vendorTerrific, 'vendor/terrific', {
		value: require('terrific'),
		writable: false,
		enumerable: false,
		configurable: false,
	});
}

if (!(window as any).dormakaba.vendorTerrific.hasOwnProperty('vendor/tslib')) {
	Object.defineProperty((window as any).dormakaba.vendorTerrific, 'vendor/tslib', {
		value: require('tslib'),
		writable: false,
		enumerable: false,
		configurable: false,
	});
}

if (!(window as any).dormakaba.vendorTerrific.hasOwnProperty('vendor/mobx')) {
	Object.defineProperty((window as any).dormakaba.vendorTerrific, 'vendor/mobx', {
		value: require('mobx'),
		writable: false,
		enumerable: false,
		configurable: false,
	});
}

if (!(window as any).dormakaba.vendorTerrific.hasOwnProperty('vendor/@gondel/core')) {
	Object.defineProperty((window as any).dormakaba.vendorTerrific, 'vendor/@gondel/core', {
		value: require('@gondel/core'),
		writable: false,
		enumerable: false,
		configurable: false,
	});
}

if (!(window as any).dormakaba.vendorTerrific.hasOwnProperty('@dormakaba/ajax')) {
	Object.defineProperty((window as any).dormakaba.vendorTerrific, '@dormakaba/ajax', {
		value: require('@dormakaba/ajax'),
		writable: false,
		enumerable: false,
		configurable: false,
	});
}

if (!(window as any).dormakaba.vendorTerrific.hasOwnProperty('@dormakaba/notifications')) {
	Object.defineProperty((window as any).dormakaba.vendorTerrific, '@dormakaba/notifications', {
		value: require('@dormakaba/notifications'),
		writable: false,
		enumerable: false,
		configurable: false,
	});
}

if (!(window as any).dormakaba.vendorTerrific.hasOwnProperty('@dormakaba/user-service')) {
	Object.defineProperty((window as any).dormakaba.vendorTerrific, '@dormakaba/user-service', {
		value: require('@dormakaba/user-service'),
		writable: false,
		enumerable: false,
		configurable: false,
	});
}
