/**
 * The user service is a global util which will allow all apps and ui components
 * to read the current user information
 */

import {endPoints} from '@dormakaba/ajax';
import {action, observable} from 'mobx';
import TerrificConfig from '../../terrific/assets/js/configs/terrific-config/terrific-config';
import {Permission, PermissionIdentifier} from '@dormakaba/ajax/endpoints/types/emksAuthentication';

export type UserServiceStatus = 'unknown' | 'loading' | 'known';
export type EmksCustomerSegment = 'UNKNOWN' | 'CONSUMER' | 'PARTNER';
export type UserServiceType = {
	hybrisStoreName: string;
	currentUserId: string;
	isAnonymous: boolean;
	anonymousId?: string;
	rootB2BUnit: string;
	agZoB2BUnit?: string;
	newCPQStyleConfigEnabled: boolean;
	hideSecurityCardForNewSeries: boolean;
	showWarningsInCpqConfiguration: boolean;
	termsUrl: string;
	defaultLanguage: string;
	defaultLanguageUnitSelected: string;
	emks: {
		access: {
			defaultRoles: Array<string>;
			defaultPermissions: Array<Permission>;
			keySystems?: {
				[key: string]: { permissions: Array<Permission> };
			};
		};
		emksCustomerSegment: EmksCustomerSegment;
		emksStartPageConsumerUrl: string;
		emksStartPagePartnerUrl: string;
	};
};

const throwError = (message: string) => {
	throw new Error(message);
};
const siteLanguage: string = document.documentElement.getAttribute('lang') || throwError('html[lang] is missing');

// The configuration is rendered in
//   project/extensions/ekit/ekitstorefront/web/webroot/WEB-INF/tags/terrific/template/master.tag
// with the following keys:
let userServiceSiteConfigCache: UserServiceType;
/**
 * The userServiceSiteConfig is read from a json rendered into the html by the backend e.g.:
 * <script id="user-service-config" type="text/json">
 * 	// ...
 * </script>
 */
function userServiceSiteConfig(param = { forceReload: false }) {
	if (userServiceSiteConfigCache && !param.forceReload) {
		return userServiceSiteConfigCache;
	}
	const userServiceSiteConfigTag =
		document.getElementById('user-service-config') || throwError('#user-service-config is missing');
	userServiceSiteConfigCache = JSON.parse(userServiceSiteConfigTag.innerHTML);
	userServiceSiteConfigCache?.isAnonymous && sessionStorage.removeItem('filter');
	return userServiceSiteConfigCache;
}

/**
 * The UserService provides all values which a specific to the current user session
 * like the current language, the current store name or the loggin state
 */
export class UserService {
	@observable status: UserServiceStatus = 'unknown';
	@observable loggedIn: boolean;
	/**
	 * User language e.g. 'en'
	 */
	@observable language: string = siteLanguage;
	/**
	 * The current occ ajax request token
	 */
	@observable token: string;
	/**
	 * KeySystem permissions for handling restricted components
	 */
	@observable
	keySystemPermissions: {
		[key: string]: {
			permissions: Array<Permission>;
		};
	} = userServiceSiteConfig().emks.access.keySystems || {};

	/**
	 * The backend hybris instance name
	 * e.g. 'ekit'
	 */
	get hybrisStoreName(): string {
		// Parse config lazy once it is needed:
		return userServiceSiteConfig().hybrisStoreName;
	}

	/**
	 * The backend hybris instance name
	 * e.g. 'ekit'
	 */
	get rootB2BUnit(): string {
		return userServiceSiteConfig().rootB2BUnit;
	}

	get agZoB2BUnit(): string {
		return userServiceSiteConfig().agZoB2BUnit;
	}

	/**
	 * The backend hybris instance name
	 * e.g. 'ekit'
	 */
	get termsUrl(): string {
		// Parse config lazy once it is needed:
		return userServiceSiteConfig().termsUrl;
	}

	/**
	 * Returns the emks default user roles
	 */
	get defaultRoles(): Array<string> {
		// Parse config lazy once it is needed:
		return userServiceSiteConfig().emks.access.defaultRoles || [];
	}

	/**
	 * Returns the emks default user permissions
	 */
	get defaultPermissions(): Array<Permission> {
		// Parse config lazy once it is needed:
		return userServiceSiteConfig().emks.access.defaultPermissions || [];
	}

	/**
	 * Returns the URL of the emks start page
	 */
	get emksStartPageUrl(): string {
		// Parse config lazy once it is needed:
		if (this.emksCustomerSegment === 'PARTNER') {
			return userServiceSiteConfig().emks.emksStartPagePartnerUrl;
		} else if (this.emksCustomerSegment === 'CONSUMER') {
			return userServiceSiteConfig().emks.emksStartPageConsumerUrl;
		}
		return undefined;
	}

	/**
	 * Returns the emks customer segment
	 */
	get emksCustomerSegment(): EmksCustomerSegment {
		// Parse config lazy once it is needed:
		return userServiceSiteConfig().emks.emksCustomerSegment || 'UNKNOWN';
	}

	/**
	 * Sets the emks customer segment
	 */
	set emksCustomerSegment(value: EmksCustomerSegment) {
		userServiceSiteConfig().emks.emksCustomerSegment = value;
	}

	/**
	 * The current user name
	 * e.g. 'test1@example.com'
	 */
	get currentUserId(): string {
		// Parse config lazy once it is needed:
		return userServiceSiteConfig().currentUserId || 'current';
	}

	/**
	 *  For B2B Unit default language
	 */
	get defaultLanguage(): string {
		return userServiceSiteConfig().defaultLanguage;
	}

	/**
	 *  current B2B Unit default language
	 */
	get defaultLanguageB2BUnit(): string {
		return userServiceSiteConfig().defaultLanguageUnitSelected;
	}

	/**
	 * Returns if the current user is anonymous
	 */
	get isAnonymous(): boolean {
		return userServiceSiteConfig().isAnonymous;
	}

	/**
	 * Returns if emks customer segment is consumer
	 */
	get isEmksConsumer(): boolean {
		// Parse config lazy once it is needed:
		return userServiceSiteConfig().emks.emksCustomerSegment === 'CONSUMER';
	}

	/**
	 * Returns if emks customer segment is partner
	 */
	get isEmksPartner(): boolean {
		// Parse config lazy once it is needed:
		return userServiceSiteConfig().emks.emksCustomerSegment === 'PARTNER';
	}

	/**
	 * Returns if the current site is configured to hide the security card panel for a new series.
	 */
	get isHideSecurityCardForNewSeries(): boolean {
		return userServiceSiteConfig().hideSecurityCardForNewSeries;
	}

	/**
	 * Returns if the current site is configured to show new CPQ style for Config.
	 */
	get isNewCPQStyleConfigEnabled(): boolean {
		return userServiceSiteConfig().newCPQStyleConfigEnabled;
	}

	get isShowWarningsInCpqConfiguration(): boolean {
		return userServiceSiteConfig().showWarningsInCpqConfiguration;
	}

	@action
	loadUser() {
		if (this.status === 'loading' || this.token) {
			return;
		}
		this.status = 'loading';

		// only fetch token if user is logged in
		if (!this.isAnonymous) {
			endPoints.oAuthController.getToken
				.ajax({ keys: { encodedContextPath: TerrificConfig.config.encodedContextPath } })
				.then(
					this._receiveUserBackendData.bind(this, 'loggedIn'),
					this._receiveUserBackendData.bind(this, 'loggedOut')
				)
				.catch(error => {
					sessionStorage.removeItem('filter');
					console.warn('ERROR', error)
				});
		} 
	}

	@action
	logout() {
		this.status = 'unknown';
		this.token = undefined;
		this.loggedIn = false;
		this.keySystemPermissions = {};
		sessionStorage.removeItem('filter');
	}

	@action
	_receiveUserBackendData(responseStatus: 'loggedIn' | 'loggedOut', token) {
		responseStatus === 'loggedOut' && sessionStorage.removeItem('filter');
		this.loggedIn = responseStatus === 'loggedIn';
		this.token = this.loggedIn ? token : undefined;
		this.status = 'known';
	}
	/**
	 * Update the permissions of a specified keySystem
	 * Can used for automatically rerendering a component with permission restriction
	 */
	@action
	setKeySystemPermissions(keySystemCode: string, permissions: Array<Permission>) {
		const newMksPermissions = { ...this.keySystemPermissions };
		newMksPermissions[keySystemCode] = { permissions: permissions };
		this.keySystemPermissions = newMksPermissions;
	}
	/**
	 * Updates the permissions of a set of different keySystems
	 */
	@action
	setMultipleKeySystemPermissions(keySystemsObj: { [keySystemCode: string]: { permissions: Array<Permission> } }) {
		if (!keySystemsObj) {
			throw new Error('keySystems not defined!');
		}
		Object.keys(keySystemsObj).forEach(keySystemCode => {
			const keySystem = keySystemsObj[keySystemCode];
			if (keySystem.permissions && keySystem.permissions.length > 0) {
				this.setKeySystemPermissions(keySystemCode, keySystem.permissions);
			}
		});
	}
	/**
	 * Returns the status of a permission of a specified keySystem
	 * Can used for permission restriction to handle a component
	 */
	@action
	hasKeySystemPermission(keySystemCode: string, identifier: PermissionIdentifier): boolean {
		if (!this.keySystemPermissions[keySystemCode] || !this.keySystemPermissions[keySystemCode].permissions) {
			console.warn(`Permissions for keySystemCode: "${keySystemCode}" not exists.`);
			return false;
		}
		const { permissions } = this.keySystemPermissions[keySystemCode];
		return permissions.some(p => p.identifier === identifier && p.access === 'ALLOWED');
	}

	@action
	reloadConfig() {
		userServiceSiteConfig({ forceReload: true });
	}

	/**
	 * Returns the emks default user permissions
	 */
	hasDefaultPermission(permissionIdentifier: string): boolean {
		// Parse config lazy once it is needed:
		const permissions = userServiceSiteConfig().emks.access.defaultPermissions || [];
		return permissions.some(
			permission => permission.identifier === permissionIdentifier && permission.access === 'ALLOWED'
		);
	}
}

// Create public reference for prod environment
const globalNamespace: { '@dormakaba/userService'?: { userService: UserService } } = (window as any).dormakaba;
globalNamespace['@dormakaba/userService'] = globalNamespace['@dormakaba/userService'] || {
	userService: new UserService(),
};
// Export for dev environment
export const userService = globalNamespace['@dormakaba/userService']!.userService;
