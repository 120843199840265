/**
 * Function to generate a 32bit integer hash code from a string
 * (uses https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/)
 * @param str
 */
export const generateHash = (str: string): string => {
	let hash: number = 0;
	if (str.length === 0) {
		return hash.toString();
	}
	for (let i = 0; i < str.length; i++) {
		let char = str.charCodeAt(i);
		hash = (hash << 5) - hash + char;
		hash = hash & hash; // Convert to 32bit integer
	}
	return hash.toString();
};
