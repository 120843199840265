import { addNotification, MessageType } from '@dormakaba/notifications';

type ErrorResponseError = {
	errorId: string;
	message: string;
	stackTrace: string;
	technicalMksMessage?: string;
};

type ErrorResponse = {
	errors: Array<ErrorResponseError>;
};

export function showErrorMessage(xmlRequest: XMLHttpRequest) {
	const errorDetails = extractErrors(xmlRequest);
	errorDetails.errors.forEach(error => {
		// Optional Technical MKS Message will be placed on second line to make debugging easier.
		const messages = [error.message, error.technicalMksMessage].filter(Boolean);
		error.errorId && messages && addNotification(MessageType.ERROR, error.errorId, messages.join('<br>'));
	});
}

/**
 * Small helper to provide a fallback if the backend error code
 * cannot be parsed properly
 */
function extractErrors(xmlRequest: XMLHttpRequest): ErrorResponse {
	const fallback: ErrorResponse = {
		errors: [
			{
				errorId: String(xmlRequest.status),
				message: xmlRequest.responseText || xmlRequest.statusText,
				stackTrace: '',
			},
		],
	};
	// Try to overwrite fallback message with backend response
	try {
		const parsedDetails: ErrorResponse = JSON.parse(xmlRequest.responseText);
		if (!parsedDetails.errors[0].message) {
			return fallback;
		}
		return parsedDetails;
	} catch (e) {
		return fallback;
	}
}
