import {generateHash} from '@dormakaba/utils';
import {ErrorInterceptor} from '../errorInterceptor';
import {showErrorMessage} from '../errorMessage';
import {EndpointCache} from './cache';
import {authorizationErrorInterceptor} from '../authorizationErrorInterceptor';

export interface EndpointHeader {
	[headerOption: string]: string;
}

export interface EndpointHeaderOptions {
	authToken?: string;
	defaultContentTypeJsonUTF8?: boolean;
	requestedWith?: string;
	customHeaders?: EndpointHeader;
}

export interface EndpointOptions<TKeys, TBody, TResult> {
	/** Header options for this endpoint */
	headerOptions?: EndpointHeaderOptions;
	/** Function that gets called after getting the success response */
	afterSuccess?: (result: TResult) => void;
	/** Function that gets called after getting the error response */
	afterError?: (err: XMLHttpRequest, ajax: AbstractEndpoint<TKeys, TBody, TResult>) => void;
	/** Subscribe to cache clear events for this endpoint */
	onCacheClear?: (listener: (lastCacheClear: Date) => void) => void;
	/** Unsubscribe from cache clear events for this endpoint */
	offCacheClear?: (listener: (lastCacheClear: Date) => void) => void;
	timeout?: number;
}

export interface EndpointRequestOptions<TKeys, TBody> {
	keys: TKeys;
	body?: TBody;
	/** Header options for this endpoint */
	headerOptions?: EndpointHeaderOptions;
	/** Interceptor that gets called when an error occurs. It's the first in the pipeline and has to return a promise that influences the rest of the pipeline */
	errorInterceptors?: Array<ErrorInterceptor>;
	omitErrorMessage?: Boolean;
	retryReasons?: Array<string>;
}

export abstract class AbstractEndpoint<TKeys, TBody, TResult> {
	private readonly cache: EndpointCache<Promise<TResult>>;
	private readonly url: (keys: TKeys) => string;
	private readonly cacheEnabled: boolean;
	private readonly options: (keys: TKeys) => EndpointOptions<TKeys, TBody, TResult>;

	constructor({
		url,
		cacheEnabled = false,
		options,
	}: {
		url: (keys: TKeys) => string;
		cacheEnabled?: boolean;
		options?: (keys: TKeys) => EndpointOptions<TKeys, TBody, TResult>;
	}) {
		this.url = url;
		this.cacheEnabled = cacheEnabled || false;
		this.options = options || (() => ({}));
		if (this.cacheEnabled) {
			this.cache = new EndpointCache<Promise<TResult>>();
		}
	}

	/**
	 * Performs the request and returns a promise as a result
	 * @param keys Parameters used to build the request
	 * @param body Request body
	 * @param retryReasons Reasons for retrying
	 */
	public ajax = (params: EndpointRequestOptions<TKeys, TBody>): Promise<TResult> => {
		const { keys, body, headerOptions, errorInterceptors, omitErrorMessage = false } = params;
		const options = { ...this.getOptions(keys), headerOptions: headerOptions };
		const ajaxResult: Promise<TResult> = this.doRequestInternal(keys, body);

		return ajaxResult
			.catch(err => {
				if (errorInterceptors) {
					return errorInterceptors.reduce(
						(prev: Promise<any>, errorInterceptor: ErrorInterceptor) =>
							prev.catch(prevError =>
								errorInterceptor
									.handleRequest(prevError)
									.then(() => this.ajax(params))
									.catch(prevError => Promise.reject(prevError))
							),
						Promise.reject(err)
					);
				}
				return ajaxResult;
			})
			.catch(err => {
				return authorizationErrorInterceptor.handleRequest(err) === undefined
					? ajaxResult
					: Promise.reject(err);
			})
			.catch(error => {
				if (!omitErrorMessage) {
					showErrorMessage(error);
				}
				if (options.afterError) {
					options.afterError(error, this);
				}
				return ajaxResult;
			})
			.then(result => {
				if (options.afterSuccess) {
					options.afterSuccess(result);
				}
				return result;
			});
	};

	/**
	 * Returns a unique entry key for the cache based on the given parameters
	 * @param keys Parameters used to build the request
	 */
	protected getCacheEntryKey = (keys: TKeys): string => {
		return generateHash(this.getHeader(keys) + ' : ' + this.getUrl(keys));
	};

	/**
	 * Returns the url
	 * @param keys Parameters used to build the request
	 */
	public getUrl = (keys: TKeys) => this.url(keys);

	/**
	 * Returns the url
	 * @param keys Parameters used to build the request
	 */
	protected getOptions = (keys: TKeys) => this.options(keys);

	/**
	 * Returns the header as a string from the given parameters
	 * @param keys Parameters used to build the request
	 */
	protected getHeader = (keys: TKeys): string => {
		const headers = AbstractEndpoint.createHeaders(this.getOptions(keys).headerOptions);
		// all uneven indexed values are keys
		const headerParts = JSON.stringify(headers).split(/\{([^\ "}]+)\}/);
		if (!headers) {
			return headerParts.join('');
		}
		// Replace all odd pieces of urlParts with the fitting value
		return headerParts.map((value, index) => (index % 2 ? headers[value] : value)).join('');
	};

	/**
	 * Creates default headers which can be overwritten by customerHeaders
	 * @param options Header options
	 */
	private static createHeaders = (options: EndpointHeaderOptions): EndpointHeader => {
		// set default values for empty options
		let opts = options || {};
		opts = {
			defaultContentTypeJsonUTF8:
				opts.defaultContentTypeJsonUTF8 !== undefined ? opts.defaultContentTypeJsonUTF8 : true,
			customHeaders: opts.customHeaders || {},
			authToken: opts.authToken,
			requestedWith: opts.requestedWith || 'XMLHttpRequest',
		};
		return {
			...(opts.defaultContentTypeJsonUTF8 && { 'Content-Type': 'application/json; charset=UTF-8' }),
			...(opts.authToken && { Authorization: 'Bearer ' + opts.authToken }),
			...(opts.requestedWith && { 'X-Requested-With': opts.requestedWith }),
			...(opts.customHeaders && opts.customHeaders),
		};
	};

	/**
	 * Subscribes a listener when clearing the cache
	 */
	public onCacheClear = (listener: (lastCacheClear: Date) => void) => {
		if (this.cache) {
			this.cache.subscribeToCacheClear(listener);
		}
	};

	/**
	 * Unsubscribes a listener from clearing the cache
	 */
	public offCacheClear = (listener: (lastCacheClear: Date) => void) => {
		if (this.cache) {
			this.cache.unsubscribeFromCacheClear(listener);
		}
	};

	/**
	 * Clears the cache if enabled
	 */
	public clearCache = () => {
		if (this.cache) {
			this.cache.clearCache();
		}
	};

	/**
	 * Considers request caching (if enabled) and calls {@link AbstractEndpoint#request}
	 * @param keys Parameters used to build the request
	 * @param body Body of request (optional)
	 */
	private doRequestInternal = (keys: TKeys, body?: TBody): Promise<TResult> => {
		const entryKey = this.getCacheEntryKey(keys);

		// return result from cache if enabled and key is present
		if (this.cacheEnabled && this.cache.hasEntry(entryKey)) {
			return this.cache.getEntry(entryKey);
		}

		// perform the request
		const result = this.request(keys, body);

		// add request to cache if enabled
		if (this.cacheEnabled) {
			result
				.then((res: TResult) => this.cache.addEntry(entryKey, result))
				.catch((error: XMLHttpRequest) => console.warn('Omit cache entry', error));
		}

		// return actual result
		return result;
	};

	/**
	 * Function to overwrite the sending of the actual request
	 * @param keys Parameters used to build the request
	 * @param body Body of request (optional)
	 */
	protected abstract request(keys: TKeys, body?: TBody): Promise<TResult>;
}
