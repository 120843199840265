import { matchesDateIsoFormat } from '@dormakaba/utils';

/**
 * Parses an object to type TResult considering date conversion
 * @param response Response to be parsed into an object of type TResult
 */
export function parseResponse<TResult>(response: string): TResult {
	return JSON.parse(response, (key, value) => {
		if (typeof value === 'string') {
			if (matchesDateIsoFormat(value)) return new Date(value);
		}
		return value;
	});
}
