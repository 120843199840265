import { htmlEntities } from '@dormakaba/utils';

// Based on https://medium.com/@kenji.imamula/simple-and-type-safe-i18n-implementation-with-typescript-aca4b9369db3

/**
 * Copied code of p-defer v1.0.0 to garant es5 compatibility
 * -----------------------------------------------------------
 */
interface DeferredPromise<ValueType> {
	/**
	Resolves the promise with a value or the result of another promise.
	@param value - The value to resolve the promise with.
	*/
	resolve(value?: ValueType | PromiseLike<ValueType>): void;

	/**
	Reject the promise with a provided reason or error.
	@param reason - The reason or error to reject the promise with.
	*/
	reject(reason?: unknown): void;

	/**
	The deferred promise.
	*/
	promise: Promise<ValueType>;
}

const defer = <ValueType>(): DeferredPromise<ValueType> => {
	const deferred = {} as DeferredPromise<ValueType>;
	deferred.promise = new Promise((resolve, reject) => {
		deferred.resolve = resolve;
		deferred.reject = reject;
	});
	return deferred;
};

// -----------------------------------------------------------

let i18nDictionary = {};
const i18nLoadDeffereds = {};

// The export is needed for the webpack hack to remove all mocks
// Usage in webpack:
// plugins: [
// 	new webpack.NormalModuleReplacementPlugin(/^\..*\/translations$/, (result) => result.request = `@dormakaba/i18n`)
// ]
// So webpack will replace all mock files (translations.ts) with this empty mock
const emptyMock = undefined;
export default emptyMock;

/**
 * t is the shorthand for 'translation'
 * This abbreviation is widely used in many i18n modules.
 * For debugging purposes displays missing keys.
 */
function t(key: string): string {
	if (!i18nDictionary[key] && i18nDictionary[key] !== '') {
		return key;
	}
	return htmlEntities.decode(i18nDictionary[key]);
}

/**
 * Returns the i18n translation helper
 * Usage:
 *
 * ```
 * const t = i18n({ mock: 'demo'});
 * t('mock') // will return 'demo'
 * ```
 *
 * In production the mock will be ignored (and removed by webpack)
 *
 */
export function i18n<T extends {}, U = keyof T>(mockWords: T): (key: U) => string {
	// Use mock if available:
	if (mockWords as any) {
		return k => (mockWords as any)[k];
	}
	return t as any;
}

export function unsafeI18n<T extends {}>(mockWords: T): (key: string) => string {
	// Use mock if available:
	if (mockWords as any) {
		return k => (mockWords as any)[k];
	}
	return t as any;
}

/**
 * Replaces placeholders ({0}, {1}, ...) found in translation labels.
 * Note: Use singlequotes in properties to allow output in HTML as JSON.
 *
 * Example: replacePlaceholders(`Accept <a href='{0}'>Terms</a>`, ['/terms']);
 *
 * @param label The label as returned by t().
 * @param replacements Array with replacements.
 */
export function replacePlaceholders(label: string, replacements: string[]): string {
	return label.replace(/{\d}/g, () => {
		// Replace match with first replacement and remove the replacement for next iteration.
		return replacements.shift();
	});
}

/**
 * Start downloading the dictionary
 * This function is using a cache - after it is called for the
 * first time it will always fallback to the cache
 */
export function loadTranslations(dictionaryName, dictionary: { [key: string]: string }) {
	if (i18nLoadDeffereds[dictionaryName]) {
		return i18nLoadDeffereds[dictionaryName].promise;
	}
	// Add keys
	i18nDictionary = Object.assign(i18nDictionary, dictionary);
	i18nLoadDeffereds[dictionaryName] = defer();
	i18nLoadDeffereds[dictionaryName].resolve(true);
	// Return promise
	i18nLoadDeffereds[dictionaryName] = defer();
	return i18nLoadDeffereds[dictionaryName].promise;
}
