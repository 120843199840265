export class EndpointCache<TValue> {
	private cache: { [entryKey: string]: TValue } = {};
	private cacheClearListener = [];
	private lastCacheClear = new Date();

	public addEntry = (entryKey: string, requestPromise: TValue): void => {
		this.cache[entryKey] = requestPromise;
	};

	public hasEntry = (entryKey: string): boolean => {
		return Object.keys(this.cache).some(key => key === entryKey);
	};

	public getEntry = (entryKey: string): TValue => {
		return this.cache[entryKey];
	};

	public clearCache = (): void => {
		this.cache = {};
		this.lastCacheClear = new Date();
		setTimeout(() => {
			this.cacheClearListener.forEach(listener => listener(this.lastCacheClear));
		});
	};
	// CacheListener
	public subscribeToCacheClear = (listener: (lastCacheClear: Date) => void): void => {
		this.cacheClearListener.push(listener);
	};
	public unsubscribeFromCacheClear = (listener: (lastCacheClear: Date) => void): void => {
		const index = this.cacheClearListener.indexOf(listener);
		if (index > -1) {
			this.cacheClearListener.splice(index, 1);
		}
	};
}
