import { GetEndpoint } from '../../src/library/ajax/getEndpoint';
import { PostEndpoint } from '../../src/library/ajax/postEndpoint';
import { getCartSubTotal } from '../ekitTerrificHybrisController';
import { AddToCartResponse } from '../types/emksKeySystemController';
import { DifferLockSetType, NewSeriesBackendType } from '../types/emksNewSeriesController';

/**
 * Create a NewSeries (first save)
 *
 * @see https://wiki.namics.com/pages/viewpage.action?pageId=613092369
 */
export const addNewSeriesCartEntries = PostEndpoint.create<
	{
		/* Language e.g. 'en' */
		language: string;
		cartid: string;
		/* Bearer Token */
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	NewSeriesBackendType,
	AddToCartResponse
>({
	url: ({ cartid, language, hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/emks/users/${user}/carts/${cartid}/newseries/entries?lang=${language}&zkPartner=&rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getCartSubTotal.clearCache();
		},
	}),
});

/**
 * Open an existing NewSeries (using the headPositionId)
 *
 * @see https://wiki.namics.com/pages/viewpage.action?pageId=628330561
 */
export const getNewSeriesEntriesByHeadPosition = GetEndpoint.create<
	{
		/* Language e.g. 'en' */
		language: string;
		/* Bearer Token */
		token: string;
		/* HeadPosition id - an id for a group of prodcuts */
		headPosition: string;
		hybrisStore: string;
		cartId: string;
		user: string;
		rootB2BUnit: string;
	},
	DifferLockSetType
>({
	url: ({ headPosition, language, hybrisStore, cartId, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/emks/users/${user}/carts/${cartId}/seriesdefinition/${headPosition}?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

/**
 * Update an existing NewSeries (using the headPositionId)
 *
 * @see https://wiki.namics.com/pages/viewpage.action?pageId=628330561
 */
export const saveNewSeriesEntriesByHeadPosition = PostEndpoint.create<
	{
		/* Language e.g. 'en' */
		language: string;
		cartid: string;
		/* Bearer Token */
		token: string;
		/* HeadPosition id - an id for a group of prodcuts */
		headPosition: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	NewSeriesBackendType,
	AddToCartResponse
>({
	url: ({ headPosition, cartid, language, hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/emks/users/${user}/carts/${cartid}/newseries/entries/${headPosition}?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getNewSeriesEntriesByHeadPosition.clearCache();
		},
	}),
});

/**
 * Update systemHeader of an existing NewSeries
 */
export const saveNewSeriesSystemHeaderByHeadPosition = PostEndpoint.create<
	{
		/* Language e.g. 'en' */
		language: string;
		/* Bearer Token */
		token: string;
		/* HeadPosition id - an id for a group of prodcuts */
		headPosition: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	NewSeriesBackendType,
	{}
>({
	url: ({ headPosition, language, hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/headpositions/${headPosition}/new-series?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getNewSeriesEntriesByHeadPosition.clearCache();
		},
	}),
});
