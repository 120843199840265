import {AbstractEndpoint, EndpointOptions} from './common';
import {parseResponse} from './responseParser';

export class DeleteEndpoint<TKeys, TResult> extends AbstractEndpoint<TKeys, void, TResult> {
	/**
	 * Perform DELETE request
	 */
	protected request = (keys: TKeys): Promise<TResult> => {
		const requestPromise: Promise<TResult> = new Promise((resolve, reject) => {
			const request = new XMLHttpRequest();
			request.open('DELETE', this.getUrl(keys), true);
			request.timeout = this.getOptions(keys).timeout || 180000;
			const headerObject = JSON.parse(this.getHeader(keys)) as { [key: string]: string };
			const headerOptions = Object.keys(headerObject);
			headerOptions.forEach(headerOption => {
				request.setRequestHeader(headerOption, headerObject[headerOption]);
			});
			request.onload = () => {
				if (request.status >= 200 && request.status < 400) {
					let parsed;
					try {
						parsed = request.responseText ? parseResponse(request.responseText) : undefined;
					} catch (e) {
						console.error(e);
						return reject(request);
					}
					resolve(parsed);
				} else {
					reject(request);
				}
			};
			request.onerror = () => {
				reject(request);
			};
			request.send();
		});
		return requestPromise;
	};

	/**
	 * Factory method to create delete endpoint
	 */
	public static create = <TKeys, TResult>({
		url,
		options,
	}: {
		url: (keys: TKeys) => string;
		options?: (keys: TKeys) => EndpointOptions<TKeys, void, TResult>;
	}): DeleteEndpoint<TKeys, TResult> => new DeleteEndpoint<TKeys, TResult>({ url, options });
}
