const T = require('terrific');
import { i18n } from '@dormakaba/i18n';
import $ from 'jquery';
import translations from './translations';

type NotificationDataStructure = {
	messageType: MessageType;
	id: string;
	message: string;
};

export enum MessageType {
	'SUCCESS',
	'ERROR',
	'WARNING',
}

const t = i18n(translations);

const notificationTemplates = {
	[MessageType.SUCCESS]: <string>require('./notification-item-success.html'),
	[MessageType.ERROR]: <string>require('./notification-item-error.html'),
	[MessageType.WARNING]: <string>require('./notification-item-warning.html'),
};

let mountedInstance: typeof globalMessageDynamicModule;
let beforeLoad: NotificationDataStructure[] = [];

export function addNotification(messageType: MessageType, id: string, message: string) {
	if (mountedInstance) {
		id !== 'undefined' && mountedInstance.appendNewMessage(messageType, id, message);
	} else {
		beforeLoad.push({
			messageType: messageType,
			id: id,
			message: message,
		});
	}
}

export function removeNotification(id: string) {
	if (mountedInstance) {
		mountedInstance.removeMessageById(id);
	}
}

/**
 * Basic Module description.
 */
const globalMessageDynamicModule = {
	_selectors: {
		closeButton: '[data-js-global-message-close-button]',
	},

	start(resolve) {
		// this.$ctx will contain a jQuery reference to the root element in the DOM.
		this.$ctx = $(this._ctx);
		mountedInstance = this;
		beforeLoad.forEach(
			item => item.id !== 'undefined' && mountedInstance.appendNewMessage(item.messageType, item.id, item.message)
		);
		resolve();
	},

	triggerNewMessageEvent(messageElement) {
		const event = document.createEvent('Event');
		event.initEvent('message-created', true, true);
		messageElement.dispatchEvent(event);
	},

	appendNewMessage(messageType: MessageType, id: string, message: string) {
		if (this.$ctx.children().length >= 3) {
			const $closeButton = this.$ctx
				.children()
				.first()
				.find(this._selectors.closeButton);
			$closeButton.click();
		}
		const tempWrapper = document.createElement('div');
		tempWrapper.innerHTML = notificationTemplates[messageType]
			.replace('{{message}}', message)
			.replace('{{close}}', t('mks.notifications.close'));
		const messageElement = tempWrapper.firstElementChild;
		messageElement.setAttribute('data-notification-id', id);
		this.$ctx.append(messageElement);

		// Send message added event
		this.triggerNewMessageEvent(messageElement);
	},

	removeMessageById(id: string) {
		const $closeButton = this.$ctx.find(`[data-notification-id="${id}"] ${this._selectors.closeButton}`);
		$closeButton.click();
	},
};

T.Module.GlobalMessageDynamic = T.createModule(globalMessageDynamicModule);
