import { GetEndpoint } from '../../src/library/ajax/getEndpoint';
import {
	Categories,
	SystemHeaderPrices,
	SystemLockingTypes,
	EcommBusinessCase,
} from '../types/emksMasterDataController';
import { MarketSystem } from '../types/emksProductSearch';

// The following endpoints can be tested using swagger:
// http://dev-storybook-dormakaba.namics-test.com/dormakabacommercewebservices/swagger/swagger-ui.html

// https://wiki.namics.com/pages/viewpage.action?pageId=613092369
export const getMarketSystems = GetEndpoint.create<
	{
		/* Language e.g. 'en' */
		language: string;
		/* Bearer Token */
		token: string;
		hybrisStore: string;
		user: string;
		businessCase: EcommBusinessCase;
		rootB2BUnit: string;
	},
	{ marketSystems: Array<MarketSystem> }
>({
	url: ({ language, hybrisStore, user, businessCase, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keySystemMasterdata/marketSystems?businessCase=${businessCase}&zkPartner=&lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

export const getSystemLockingTypes = GetEndpoint.create<
	{
		/* Language e.g. 'en' */
		language: string;
		/* Bearer Token */
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	SystemLockingTypes
>({
	url: ({ language, hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keySystemMasterdata/systemLockingTypes?zkPartner=&lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

export const getCategories = GetEndpoint.create<
	{
		/* Language e.g. 'en' */
		language: string;
		/* Bearer Token */
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	Categories
>({
	url: ({ language, hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keySystemMasterdata/categories?lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

export const getPrices = GetEndpoint.create<
	{
		/* Language e.g. 'en' */
		language: string;
		/* Bearer Token */
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	SystemHeaderPrices
>({
	url: ({ language, hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keySystemMasterdata/prices?zkPartner=&lang=${language}&rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});
