import { DownloadEndpoint } from '../../src/library/ajax/downloadEndpoint';

// The following endpoints can be tested using swagger:
// http://dev-storybook-dormakaba.namics-test.com/dormakabacommercewebservices/swagger/swagger-ui.html

// https://wiki.namics.com/pages/viewpage.action?pageId=620233809
export const downloadPDF = DownloadEndpoint.create<
	{
		language: string;
		orderId: string;
		fileReference: string;
		fileName: string;
		mimeType?: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	void
>({
	// Mock: url: ({ orderId, hybrisStore }) => ({ cacheKey: '123', url: '/_ui/ekitstorefront/terrific/media/mock-pdf.pdf' })
	url: ({ orderId, hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2-stateful/${hybrisStore}/users/${user}/order/${orderId}/download?rootB2BUnit=${rootB2BUnit}`,
	useFileNameFromResponse: false,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});
