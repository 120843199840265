import { ApiErrorResponse, ApiError } from '../../endpoints/types/error';

export abstract class ErrorInterceptor {
	private readonly overlayClosable: boolean;

	constructor({ overlayClosable }: { overlayClosable: boolean } = { overlayClosable: false }) {
		this.overlayClosable = overlayClosable;
	}

	/**
	 * Returns if request can be handled by this interceptor
	 * @param request
	 */
	protected abstract supports(request: XMLHttpRequest): boolean;

	/**
	 * Handles common intercept logic that can be used by each individual interceptor
	 * @param request
	 * @param isOverlayClosable
	 */
	protected abstract handleRequestInternal(request: XMLHttpRequest, isOverlayClosable: boolean): Promise<any>;

	/**
	 * Handles the request
	 * @param request
	 * @param isOverlayClosable
	 */
	public handleRequest(request: XMLHttpRequest): Promise<any> {
		// if this ErrorInterceptor supports the request, then the internal handler is being executed and returned
		if (this.supports(request)) {
			return this.handleRequestInternal(request, this.overlayClosable);
		}

		// return reject promise if this ErrorInterceptor will not handle the request
		return Promise.reject(request);
	}

	/**
	 * Returns the api error response if errorXmlRequest is an api error object. Otherwise it returns 'undefined'.
	 * @param errorXmlRequest
	 */
	protected getApiErrorResponse = (errorXmlRequest: XMLHttpRequest): ApiError => {
		let errorResponse: ApiErrorResponse;
		try {
			errorResponse = JSON.parse(errorXmlRequest.responseText);
			if (!errorResponse && !errorResponse.errors && errorResponse.errors.length <= 0) {
				console.warn(
					new Error(
						`Could not parse authentication error: ${errorXmlRequest.status} ${JSON.stringify(
							errorXmlRequest.responseText
						)}`
					)
				);
			}
			return errorResponse.errors[0];
		} catch (e) {
			console.warn(
				new Error(
					`Could not parse authentication error: ${errorXmlRequest.status} ${JSON.stringify(
						errorXmlRequest.responseText
					)}`
				)
			);
		}

		return undefined;
	};
}
