import { ErrorInterceptor } from './errorInterceptor';
import { ApiError } from '../../endpoints/types/error';

class AuthorizationErrorInterceptor extends ErrorInterceptor {
	supportedStatusCodes: Array<number> = [401];
	supportedErrorIds: Array<string> = ['account.error.unauthorized.user'];

	protected supports(request: XMLHttpRequest): boolean {
		return this.supportedStatusCodes.some(statusCode => request.status === statusCode);
	}

	protected handleRequestInternal(request: XMLHttpRequest, isOverlayClosable: boolean): Promise<any> {
		const apiError = this.getApiErrorResponse(request);
		if (apiError) {
			const supportsApiErrorId = apiError && this.supportedErrorIds.some(errorId => errorId === apiError.errorId);
			if (supportsApiErrorId) {
				window.location.reload();
				return undefined;
			}
		}
		return Promise.reject(request);
	}

	protected getApiErrorResponse = (errorXmlRequest: XMLHttpRequest): ApiError => {
		try {
			return JSON.parse(errorXmlRequest.responseText);
		} catch (e) {
			console.warn(
				new Error(
					`Could not parse authentication error: ${errorXmlRequest.status} ${JSON.stringify(
						errorXmlRequest.responseText
					)}`
				)
			);
		}

		return undefined;
	};
}

export const authorizationErrorInterceptor = new AuthorizationErrorInterceptor();
